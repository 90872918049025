import { useState, useRef, useEffect } from 'react';
import './station.css';

import arrowimg from "./img/arrow.svg";
import lockimg from "./img/lock.svg";

import ContentVideo from './contenttypes/ContentVideo';
import ContentQuiz from './contenttypes/ContentQuiz';
import ContentGame from './contenttypes/ContentGame';

//import StationSuccess from './StationSuccess';

import { useParams, useNavigate } from 'react-router';


function Teststation() {

  let { tourid, stationid } = useParams();

  const navigate = useNavigate();


  

  const [index, setIndex] = useState(0);

  

  const [lockedContents, setLockedContents] = useState([]);
  console.log(lockedContents);

  const handleContentCompletion = () => {
    const updatedLockedContents = [...lockedContents];
    updatedLockedContents[index] = false;
    setLockedContents(updatedLockedContents);
  };

  function indexprev() {
    if (index > 0) {
      setIndex(prev => prev - 1);
    }
  }

  function indexnext() {
    if (index < data.contents.length - 1) {
      setIndex(prev => prev + 1);
    } else {
      markStationAsDone();
      navigate(`/${tourid}`);
    }
  }

  function markStationAsDone() {
    const key = `donestations-${tourid}`;
    const existingStations = JSON.parse(localStorage.getItem(key)) || []; // Load existing completed stations
  
    if (!existingStations.includes(parseInt(stationid))) {
      existingStations.push(parseInt(stationid)); // Add the current station id if not already present
      localStorage.setItem(key, JSON.stringify(existingStations)); // Save back to localStorage
    }
  }



  const testdata = {
    contents: [/*{
      type: "quiz",
      content: {
        question: "Welches Zeichen findest du auf dem Gemälde?",
        options: [
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune1.png' },
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune4.png' },
          { correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/rune2.png' },
          { correct: true, image: 'https://content.wunderweg.net/wunderweg/1/1/rune3.png' },
        ],
        twocolumn: true
      }
    },*/
{
      type: "video",
      content: {
        url: "https://content.wunderweg.net/wunderweg/1/1/auerhahn-de.mp4"
      }
    },
    {
      type: "quiz",
      content: {
        question: "Wie verdaut der Auerhahn seine Nahrung?",
        options: [
          { text: "Mit kleinen Steinchen im Magen", smalltext: true, correct: true, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz1.png', imagecover: true },
          { text: "Es wird von anderen Hähnen vorgekaut", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz2.png', imagecover: true },
          { text: "Er zerkaut sie mit seinen Zähnen", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz3.png', imagecover: true },
          { text: "Mit sehr starker Magensäure", smalltext: true, correct: false, image: 'https://content.wunderweg.net/wunderweg/1/1/quiz4.png', imagecover: true },
        ],
        twocolumn: true
      }
    },
    
    
    
  ]}

  const [data, setData] = useState(testdata);

  useEffect(() => {
   
  }, [])


  return (
   
    <div className="page-station">
        {data?.contents?.map((content, idx) => {
          let contentdata = content?.content;
          return (
          <div key={idx} className={`content-wrapper ${idx != index ? (idx > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            {content.type === 'video' && <ContentVideo content={contentdata} active={idx === index} baseurl={data.baseurl} />}
            {content.type === 'quiz' && <ContentQuiz content={contentdata} onCompletion={handleContentCompletion} baseurl={data.baseurl} />}
            {content.type === 'game' && <ContentGame content={contentdata} onCompletion={handleContentCompletion} onNext={indexnext} active={idx === index} baseurl={data.baseurl} />}
            
          </div>
        )
        })}

       {/* <div key={'successitem'} className={`content-wrapper ${data.contents.length != index ? (data.contents.length > index ? "content-wrapper-before" : "content-wrapper-after") : null}`}>
            <StationSuccess title={data.title} baseurl={data.baseurl} trailid={data.trailid} startAgain={() => setIndex(0)} active={data.contents.length === index} numcontents={data.contents.length} language={data.language} />
        </div>
        */}
      
      {/*contents[index].type === 'video' && <ContentVideo key={index} content={contents[index].content} />
      contents[index].type === 'quiz' && <ContentQuiz key={index} content={contents[index].content} />*/}

      <div className={`contentarrowcontainer ${data.contents.length <= index && 'contentarrowcontainerhidden'}`}>
        <div className='clickable stationbtn stationprevbtn' style={{opacity: index > 0 ? 1 : 0}}  onClick={indexprev}><img src={arrowimg} /></div>
        <div className='clickable stationbtn stationnextbtn'  onClick={indexnext}>Weiter {lockedContents[index] ? <img src={lockimg} /> : <img className='arrowmove' src={arrowimg} />}</div>
      </div>

      <style>{"body{overflow:hidden;}"}</style>
    </div>
    )
}
export default Teststation;
