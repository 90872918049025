import './App.css';

import HomeScreen from './HomeScreen';
import TourScreen from './TourScreen';
import Teststation from './TestStation';

import { BrowserRouter, Routes, Route } from "react-router";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/:tourid" element={<TourScreen />} />
        <Route path="/:tourid/:stationid" element={<Teststation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
