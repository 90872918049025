
import { useNavigate } from "react-router";

const TourCard = ({ tour, apiurl, client }) => {
  const backgroundImage = `${apiurl}${client}/${tour.id}/image.png`;
  const logoImage = `${apiurl}${client}/${tour.id}/logo.svg`;

  let navigate = useNavigate();

  function playagain() {
    // Remove the "donestations-{id}" from localStorage
    localStorage.removeItem(`donestations-${tour.id}`);
  
    // Remove the tourid from the "doneTours" array in localStorage
    const doneTours = JSON.parse(localStorage.getItem("doneTours")) || [];
    const updatedDoneTours = doneTours.filter((id) => id !== tour.id); // Filter out the current tour.id
    //localStorage.setItem("doneTours", JSON.stringify(updatedDoneTours));
  
    // Optionally navigate to restart the tour
    navigate(`/${tour.id}`);
  }

  return (
    <div
      className="tourcard"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="tourinfos">
        <img
          className="traillogo"
          src={logoImage}
          style={{ width: `${tour.logowidth}%` }}
          alt={tour.name}
        />
        <div className="primarybutton shinybutton clickable" onClick={() => tour.done ? playagain() : navigate(`/${tour.id}`)}>{tour.done ? "Nochmal starten" : "Jetzt starten"}</div>
      </div>
        {
          tour.done && <div className="tourdonebadge">✓ Geschafft</div>
        }
    </div>
  );
};

export default TourCard;
