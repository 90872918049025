import './App.css';

import { useState, useEffect } from 'react';

import Masonry from 'react-masonry-css'

import closeimg from "./img/close.svg";
import checkmark from "./img/checkmark.svg";

import mapicon from "./img/map.svg";
import scanicon from "./img/scan.svg";

import ModalScan from './components/ModalScan';
import ModalMap from './components/ModalMap';

import { useParams, useNavigate } from "react-router";

function TourScreen() {

  let {tourid} = useParams();
  let navigate = useNavigate();

  const [data, setData] = useState(null);

  const [scanmodalopen, setscanmodalopen] = useState(false);
  const [mapmodalopen, setmapmodalopen] = useState(false);

  const apiurl = "https://museum.wunderweg.net/clients/salzburgmuseum/";

  const mockdata = [
    // Museums Ralley
    {
    tourid: 1,
    description: "Entdecke das Museum spielerisch! Eine spannende Schnitzeljagd voller Rätsel und Überraschungen erwartet dich.",
    stations: [
      {
        id: 1,
        number: "1",
        title: "Das Portal",
        ratio: 1.5,
        position: [30,2]
      },
      {
        id: 2,
        number: "2a",
        title: "Margareth XI",
        ratio: 1.2,
        position: [3,67]
      },
      {
        id: 3,
        number: "2b",
        title: "Der Jäger und seine Hunde",
        ratio: 1.1,
        position: [57,27]
 
      },
      {
        id: 4,
        number: "3",
        title: "Sitzender Akt",
        ratio: 1,
        position: [3,13]
        
      },
      {
        id: 5,
        number: "4",
        title: "Gracht in Utrecht",
        ratio: 1.6,
        position: [23,87]

      },
      {
        id: 6,
        number: "5a",
        title: "Klimts Geheimnis",
        ratio: 2,
        position: [80,50]
  
      }
    ]
  },
  // Antike
  {
    tourid: 2,
    description: "Tauche ein in die faszinierende Welt der Antike – erlebe beeindruckende Artefakte und Geschichten längst vergangener Zeiten.",
    stations: [
      {
        id: 7,
        number: "1",
        title: "Römischer Junge",
        ratio: 1.5,
        position: [30,2]
      },
      {
        id: 8,
        number: "2a",
        title: "Die Dichterin",
        ratio: 1,
        position: [3,67]
      },
      {
        id: 9,
        number: "2b",
        title: "Laokoon und seine Söhne",
        ratio: 1.1,
        position: [57,27]
 
      },
      {
        id: 10,
        number: "3",
        title: "Tutanchamun",
        ratio: 1.2,
        position: [3,13]
        
      },
      {
        id: 11,
        number: "4",
        title: "Die Vase",
        ratio: 1.6,
        position: [23,87]

      },
      {
        id: 12,
        number: "5a",
        title: "Der Kaiser",
        ratio: 2,
        position: [80,50]
  
      }
    ]
  },
  // Picassos Rätsel
  {
    tourid: 3,
    description: "Entschlüssele die Geheimnisse von Picassos Kunstwerken und entdecke neue Perspektiven auf seine Meisterwerke.",
    stations: [
      {
        id: 13,
        number: "1",
        title: "Der Traum",
        ratio: 1.5,
        position: [30,2]
      },
      {
        id: 14,
        number: "2a",
        title: "Frau mit grünem Hut",
        ratio: 1.2,
        position: [3,67]
      },
      {
        id: 15,
        number: "2b",
        title: "Dora Maar",
        ratio: 1,
        position: [57,27]
 
      },
      {
        id: 16,
        number: "3",
        title: "Selbstbildnis",
        ratio: 1.2,
        position: [3,13]
        
      },
      {
        id: 17,
        number: "4",
        title: "Sculpture et Vase de Fleurs",
        ratio: 1.6,
        position: [23,87]

      },
      {
        id: 18,
        number: "5a",
        title: "Bust of a Woman",
        ratio: 1.7,
        position: [80,50]
  
      }
    ]
  }
]

  const donestations = data?.stations.filter(obj => obj.done);

  useEffect(() => {
    localStorage.setItem("activetour", tourid);
    // Load `done` status for this tour's stations from localStorage
    let localdonestations = JSON.parse(localStorage.getItem(`donestations-${tourid}`)) || [];

    // Fetch the data for the selected tour
    const tourData = mockdata.find((tour) => tour.tourid === parseInt(tourid));
    if (!tourData) {
      leave();
      return;
    }

    // Add `done` property dynamically based on localStorage
    const updatedStations = tourData.stations.map((station) => ({
      ...station,
      done: localdonestations.includes(station.id),
    }));

    // Update the state with modified data
    setData({ ...tourData, stations: updatedStations });

    // Check if all stations are done
    const allStationsDone = updatedStations.every((station) => station.done);
    if (allStationsDone) {
      let doneTours = JSON.parse(localStorage.getItem("doneTours")) || [];
      if (!doneTours.includes(parseInt(tourid))) {
        doneTours.push(parseInt(tourid));
        localStorage.setItem("doneTours", JSON.stringify(doneTours));
      }
      console.log("done!");
      leave();
    }

  }, [tourid, navigate]);

function leave() {
  localStorage.removeItem("activetour");
  navigate("/");
}


  return (
    data != null ?
    <div className="page-tour">
      <div className='wrapper wrappertour'>
        <div className='tourheader'>
          <div className='closebutton clickable' onClick={() => leave()}><img src={closeimg} /></div>
          <div className='progressbar'>
            <div className='progressbarinner' style={{width: `${donestations.length / data.stations.length * 100}%`}}></div>
          </div>
          <div><b>{donestations.length}</b>/{data.stations.length}</div>
        </div>

        <div className='tourdetails'>
          <div className='tourpagelogo buildup-up'>
            <img className='traillogo' src={apiurl + tourid + "/logocolor.svg"} style={{width: "75%"}} />
          </div>
          <p className='buildup-up' style={{animationDelay: '100ms'}}>
            {
              data.description
            }
          </p>
        </div>

        <Masonry
          breakpointCols={2}
          className="masonry-grid"
          columnClassName="masonry-grid_column">
          {
            data.stations.map((obj, index) =>
              <div key={index} 
              onClick={() => navigate(`/${tourid}/${obj.id}`)}
              className='stationcard buildup-up clickable' style={{
                backgroundImage: `url(${apiurl + tourid + "/" + obj.id + "/image.png"})`,
                aspectRatio: 1 / obj.ratio || 1 / 1.4,
                animationDelay: 200 + (index * 50) + "ms"
              }}>
                <span className='stationcardnumber'>{obj.number}</span>
                <div className='stationcardtitle'>{obj.title}</div>
                {obj.done && <img src={checkmark} className='checkmark' />}
                {obj.done && <div className='donelayer'></div>}
              </div>
            )
          }
        </Masonry>

      </div>

      <div className='tourdetailbuttonbox buildup-up' style={{animationDelay: '500ms'}}>
        <div className='wrapper buttonwrapper'>
        <div className='primarybutton primarybuttonwithicon clickable' onClick={() => setscanmodalopen(true)}>
          <img src={scanicon} />
          Station finden
        </div>
        <div className='mapbutton clickable' onClick={() => setmapmodalopen(true)}>
          <img src={mapicon} />
        </div>
        </div>
      </div>

      <ModalScan open={scanmodalopen} onClose={() => setscanmodalopen(false)} />
      <ModalMap open={mapmodalopen} onClose={() => setmapmodalopen(false)} tourid={data.tourid} stations={data.stations} />

      {scanmodalopen || mapmodalopen ? <style>{"body{overflow:hidden;}"}</style> : null}
    </div>
    : 
    <div>Loading</div>
  );
}

export default TourScreen;
