
import closeimg from "../img/close.svg";


function ModalMap(props) {

  const apiurl = "https://museum.wunderweg.net/clients/salzburgmuseum/";
  const tourid = 1;

  return (
    <div className={`modal ${props.open ? "" : "modalclosed"}`} >
      <div className="modalbackground" onClick={() => props.onClose()}></div>
      <div className="modalinner modalmap-inner" >
        <div className="wrapper">
          <div className="modalheader">
              <p>Kartenansicht</p>
              <img src={closeimg} onClick={() => props.onClose()} />
            </div>
        </div>
        <div className="map">
         
          <div className="mapgroup">
            <img className="mapimg" src={apiurl + "1" + "/map.png"} />
            {
              props.stations.map((obj, index) =>
                <div className="mapstationdot" key={index} style={{
                  backgroundImage: `url(${apiurl + props.tourid + "/" + obj.id + "/image.png"})`,
                  left: obj.position[0] + "%",
                  top: obj.position[1] + "%"
                }}></div>
              )
            }
          </div>
        </div>
        {/*<div className="wrapper">
          <div className="primarybutton clickable" onClick={() => props.onClose()}>Schließen</div>
        </div>*/}
      </div>
    </div>

  );
}

export default ModalMap;
