import React, { useEffect, useRef } from 'react';
import TourCard from './components/TourCard';
import './App.css';

import { useNavigate } from "react-router";

function HomeScreen() {
  const apiurl = "https://museum.wunderweg.net/clients/";

  let navigate = useNavigate();

  const initialdata = {
    client: "salzburgmuseum",
    tours: [
      {
        id: 1,
        name: "Museums Ralley",
        slug: "museumsralley",
        logowidth: 75,
        
      },
      {
        id: 2,
        name: "Die Antike",
        slug: "antike",
        logowidth: 80,
      },
      {
        id: 3,
        name: "Picassos Rätsel",
        slug: "picasso",
        logowidth: 75,
      },
    ],
  };

  let doneTours = JSON.parse(localStorage.getItem('doneTours')) || [];

  const data = {
    ...initialdata, // Spread the existing properties (like `client`)
    tours: initialdata.tours.map((tour) => ({
      ...tour,
      done: doneTours.includes(parseInt(tour.id)), // Add `done` status dynamically
    })),
  };

  const containerRef = useRef(null);

  useEffect(() => {

    if(localStorage.getItem("activetour") != null) {
      navigate(`/${localStorage.getItem("activetour")}`);
    }

    const handleScroll = () => {
      const cards = document.querySelectorAll('.tourcard');
      const container = containerRef.current;

      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const containerCenter = containerRect.left + containerRect.width / 2;

      cards.forEach((card) => {
        
        const cardRect = card.getBoundingClientRect();
        const cardCenter = cardRect.left + cardRect.width / 2;
        const parallaxOffset = (cardCenter - containerCenter) * -0.1; // Adjust speed (0.2 is the slower rate)
        card.style.backgroundPositionX = `${parallaxOffset}px`;
      });
    };

    // Attach scroll listener
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    // Initial call
    handleScroll();

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <div className="headernew">
        <img
          className="clientlogonew"
          src={`${apiurl}${data.client}/logocolor.svg`}
          alt="Logo"
        />
        <div className='headerprogress'>
          <span className='headerprogressbignumber'>{data.tours.filter(obj => obj.done).length}</span>/{data.tours.length}
        </div>
      </div>

      <div className="tourhorizontallist" ref={containerRef}>
        {data.tours.map((tour, index) => (
            <TourCard key={tour.id} tour={tour} apiurl={apiurl} client={data.client} />
        ))}
      </div>
    </div>
  );
}

export default HomeScreen;
