import scanframeimg from "../img/scanframe.svg";
import scangreen from "../img/scangreen.svg";

import closeimg from "../img/close.svg";

import { Scanner } from '@yudiel/react-qr-scanner';

import {useNavigate } from "react-router";


function ModalScan(props) {

  let navigate = useNavigate();

  const handleScan = (result) => {
    console.log();
    if (result[0]?.rawValue) {
      try {
        const scannedUrl = new URL(result[0]?.rawValue); // Parse the scanned result as a URL
        const currentDomain = window.location.hostname; // Get the current domain
        const isValidDomain = scannedUrl.hostname === currentDomain; // Check if the domains match
        
        if (isValidDomain) {
          const pathParts = scannedUrl.pathname.split('/').filter(Boolean); // Get the path segments
          if (pathParts.length === 2) {
            const [tourId, stationId] = pathParts;
            if (!isNaN(tourId) && !isNaN(stationId)) {
              // Redirect if valid
              window.location.href = result[0]?.rawValue;
            } else {
              alert("Invalid path format. Tour ID and Station ID must be numbers.");
            }
          } else {
            alert("Invalid URL path. Expected format: /tourId/stationId.");
          }
        } else {
          alert("Scanned QR code does not belong to this domain.");
        }
      } catch (error) {
        alert("Invalid QR code. Please scan a valid URL.");
      }
    }
  };

  return (
    <div className={`modal ${props.open ? "" : "modalclosed"}`} >
      <div className="modalbackground" onClick={() => props.onClose()}></div>
      <div className="modalinner" >
        <div className="wrapper wrappermodal">
            <div className="modalheader">
              <p>Scanne einen QR Code</p>
              <img src={closeimg} onClick={() => props.onClose()} />
            </div>
            {/*<div className="scancamera">
                <div className="scanframebox">
                    <img src={scanframeimg} className="scanframeimg" />
                    <img src={scangreen} className="scangreen" />
                </div>
            </div>*/}
            {props.open &&
            <div className="scancamera">
            <Scanner classNames="scancamera" onScan={(result) => handleScan(result)} >
            <div className="scanframebox">
                    <img src={scanframeimg} className="scanframeimg" />
                    <img src={scangreen} className="scangreen" />
                </div>
            </Scanner>
            </div>
}
            {/*<div className="primarybutton clickable" onClick={() => props.onClose()}>Schließen</div>*/}
        </div>
      </div>
    </div>

  );
}

export default ModalScan;
