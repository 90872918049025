// Audio Player with second buttons

import { useState, useRef, useEffect } from 'react';
import retryimg from "../img/retry_white.svg";
import pauseicon from "../img/pause_dark.svg";



function ContentGame(props) {

  const [overlayopen, setOverlayopen] = useState(true);
  const [finished, setFinished] = useState(false);

  const iframeref = useRef(null);

  const handleMessage = (event) => {
    // Handle the message from the iframe
    const { data } = event;
    console.log('Message received from iframe:', data);
    // You can perform actions based on the received message
    if (data === 'finish') {
      // Game is finished, do something
      console.log('Game finished!');
      //alert("finished!");
      stop();
      //props.onNext();
    } else {
      console.log("notit");
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    console.log(props.baseurl + props.content.url);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  function start() {
    props.showButtons(false);
    iframeref.current.contentWindow.postMessage('reload', '*');
    setOverlayopen(false);
  }

  function stop() {
    setFinished(true);
    props.onCompletion();
    setOverlayopen(true);
    props.showButtons(true);
  }

  /*
  useEffect(() => {
    if (!props.active) {
      
    }
  }, [props.active])
  */

  return (
    <div className="page-content-game">
      <div className='stopgamebutton clickable' onClick={() => stop()}><img src={pauseicon} /></div>
      <div className={`content-game-overlay ${!overlayopen && "content-game-overlay-closed"}`}>
        <div className='wrapper'>
          <h2>{props.content?.title}</h2>
          <p>{props.content?.description}</p>
          {!finished && <div className='button-primary' onClick={() => start()}>Jetzt starten</div>}
          {/*finished ? <div className='button-primary' onClick={() => props.onNext()}>{translate("next", props.language)}</div> : <div className='button-primary' onClick={() => start()}>{translate("startnow", props.language)}</div>*/}
          {finished && <div className='button-secondary button-secondary-light  clickable' onClick={() => start()}><img src={retryimg} style={{width: 20, marginRight: 4}} />Nochmal starten</div>}
        </div>
      </div>
      
      <iframe 
        ref={iframeref} 
        src={props.baseurl + props.content.url}
      />
    </div>
  );
}

export default ContentGame;
